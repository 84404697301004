<template src="./FinancialItemRankingSearchBoxFloating.html" />
<script>
import FinancialItemRankingSearchBox from './FinancialItemRankingSearchBox.vue';
import FinancialItemRankingPayLockLink from './FinancialItemRankingPayLockLink.vue';
import NameModal from '../modules/Modal/NameModal.vue';
import MkIntersectionObserver from '../../utils/MkIntersectionObserver';

/**
 * 株式ランキング絞り込みFABコンポーネント
 * @vue-components {FinancialItemRankingSearchBox} FinancialItemRankingSearchBox 絞り込みモーダル
 * @module FinancialItemRanking/FinancialItemRankingSearchBoxFloating
 */
export default {
  name: 'FinancialItemRankingSearchBoxFloating',
  components: {
    FinancialItemRankingSearchBox,
    FinancialItemRankingPayLockLink,
    NameModal,
  },
  props: {
    /**
     * ランキング種別
     */
    rankingType: {
      type: String,
      default: '',
      required: true,
    },
    /**
     * 絞り込み条件オブジェクト
     */
    formElements: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * 絞り込み機能に制限があるか否か
     */
    isViewingRestrict: {
      type: Boolean,
      default: false,
    },
    /**
     * ログイン画面へのURL
     */
    loginUrl: {
      type: String,
      required: true,
    },
    /**
     * プレミアムLPへのURL
     */
    premiumLpUrl: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * FAB内のモーダルの表示状態
     */
    isLocalBoxVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /**
       * 絞り込みボタンの表示状態
       * @type {boolean}
       */
      isShow: true,
    };
  },
  computed: {
    /**
     * 絞り込み状態
     * @note 絞り込みパラメータで判定する
     */
    isFilterd() {
      return (
        this.formElements.exchangeNameId !== null ||
        this.formElements.industrySectorCode !== null ||
        this.formElements.sortOrder !== this.formElements.orders[0][0]
      );
    },
    /**
     * 配当利回りランキングか
     * @returns {boolean}
     */
    isDividendYieldRanking() {
      return this.rankingType === 'dividend_yield';
    },
  },
  mounted() {
    this.handleIntersection();
  },
  methods: {
    /**
     * ランキング表示条件の表示/非表示を設定する
     * @param bool
     */
    setBoxVisible(bool) {
      this.$emit('set-box-visible', bool);
    },
    /**
     * 監視対象の要素が表示されている場合に絞込みボタンを表示する
     * @returns {void}
     */
    handleIntersection() {
      const tableElement = document.getElementById('financial-item-rankings-table');
      const titleElement = document.getElementById('financial_item_ranking');

      const tableNoDataElement = document.getElementById('financial-item-rankings-table-no-data');
      let targetElement = tableElement || tableNoDataElement;

      let isTableElementInView = false;
      let isTitleElementInView = false;
      const callback = (entries) => {
        entries.forEach((entry) => {
          if (
            entry.target.id === 'financial-item-rankings-table' ||
            entry.target.id === 'financial-item-rankings-table-no-data'
          ) {
            isTableElementInView = entry.isIntersecting;
          } else if (entry.target.id === 'financial_item_ranking') {
            isTitleElementInView = entry.isIntersecting;
          }

          if (entry.isIntersecting) {
            this.isShow = true;
          }

          // 両方の要素が表示されていない場合は非表示
          if (!isTableElementInView && !isTitleElementInView) {
            this.isShow = false;
            // モーダル展開中に規定位置までスクロールするとFABごとモーダルが非表示となるため、その時点で閉じておく
            this.setBoxVisible(false);
            this.hidePremiumModal();
          }
        });
      };

      const observer = new MkIntersectionObserver(callback, { threshold: 0.25 });
      observer.observe(targetElement);
      observer.observe(titleElement);
    },
    /**
     * プレミアムランキングモーダルを非表示にする
     */
    hidePremiumModal() {
      this.$store.commit('hide', 'premiumRanking');
    },
  },
};
</script>
