<template src="./FinancialItemRankingCategoryHeader.html"></template>
<script>
import NameModal from '../modules/Modal/NameModal.vue';

/**
 * 株式ランキングカテゴリヘッダーコンポーネント
 * @module FinancialItemRanking/FinancialItemRankingCategoryHeader
 */
export default {
  name: 'FinancialItemRankingCategoryHeader',
  components: {
    NameModal,
  },
  props: {
    rankingType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      targetCategory: '', // 開こうとしているカテゴリ名
    };
  },
  computed: {
    /**
     * カテゴリ名のリスト
     * @return {Array<string>}
     */
    categoryNames() {
      return this.financialItemRankingCategoryList();
    },
    /**
     * 開こうとしているカテゴリ名のモーダル内で使うランキング Map のリスト
     * @return {Array<{rankingType: string, name: string, path: string}>}
     */
    rankingMaps() {
      return this.financialItemRankingTypesByCategory(this.targetCategory).map((rankingType) => ({
        rankingType,
        name: this.financialItemRankingName(rankingType),
        path: this.financialItemRankingUrl(rankingType),
      }));
    },
  },
  methods: {
    /**
     * カテゴリモーダルの表示
     * @param {string} categoryName
     * @return {void}
     */
    open(categoryName) {
      this.targetCategory = categoryName;
      this.$store.commit('show', 'rankingCategory');
    },
    /**
     * 指定したカテゴリ名が現在のカテゴリ名かどうかを判定
     * @param {string} categoryName
     * @return {boolean}
     */
    isCurrentCategory(categoryName) {
      return this.financialItemRankingCategoryName(this.rankingType) === categoryName;
    },
  },
};
</script>
