import FinancialItemRankingPayLockLink from './FinancialItemRankingPayLockLink.vue';
import FinancialItemRankingSearchBox from './FinancialItemRankingSearchBox.vue';
import FinancialItemRankingMenu from './FinancialItemRankingMenu.vue';
import FinancialItemRankingCategoryHeader from './FinancialItemRankingCategoryHeader.vue';
import NameModal from '../modules/Modal/NameModal.vue';
import CampaignBanner from '../modules/Banner/CampaignBanner.vue';
import FinancialItemRankingSearchBoxFloating from './FinancialItemRankingSearchBoxFloating.vue';

/**
 * 株式ランキング ルートコンポーネント
 * @module FinancialItemRankings
 */
const FinancialItemRankings = {
  el: '#v-financial-item-rankings',
  components: {
    FinancialItemRankingPayLockLink,
    FinancialItemRankingSearchBox,
    FinancialItemRankingMenu,
    FinancialItemRankingCategoryHeader,
    NameModal,
    CampaignBanner,
    FinancialItemRankingSearchBoxFloating,
  },
  data() {
    return {
      isBoxVisible: false,
    };
  },
  methods: {
    /**
     * 表示条件のモーダルを制御する
     * @param bool
     */
    setBoxVisible(bool) {
      this.isBoxVisible = bool;
    },
  },
};
export default FinancialItemRankings;
