<template src="./FinancialItemRankingMenu.html"></template>
<script>
/**
 * 株式ランキングメニューコンポーネント
 * @module FinancialItemRanking/FinancialItemRankingMenu
 * @property {String} rankingType - ランキングタイプ
 */
export default {
  name: 'FinancialItemRankingMenu',
  props: {
    rankingType: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * タブに表示するメニュー項目
     * @returns {Array} - ランキングタイプの配列
     */
    menuItems() {
      return this.financialItemRankingTypesByCategory(this.financialItemRankingCategoryName(this.rankingType));
    },
    /**
     * ランキングタイプの名前
     * @param {String} rankingType ランキングタイプ
     * @returns {String} - ランキングタイプの名前
     */
    menuItemName() {
      return (rankingType) => {
        return this.financialItemRankingName(rankingType);
      };
    },
    /**
     * メニューアイテムのCSS
     * @param {String} rankingType ランキングタイプ
     * @returns {String} - メニューアイテムのCSS
     */
    menuItemCss() {
      return (rankingType) => {
        return this.rankingType === rankingType ? 'bg-white border-blue-500' : 'bg-slate-200 border-slate-200';
      };
    },
    /**
     * ランキングタイプのURL
     * @param {String} rankingType ランキングタイプ
     * @returns {String} - ランキングタイプのURL
     */
    rankingURL() {
      return (rankingType) => {
        return this.financialItemRankingUrl(rankingType);
      };
    },
    /**
     * 現在のメニューアイテムか
     * @returns {Boolean} - 現在のメニューアイテムか
     */
    isSelected() {
      return (rankingType) => {
        return this.rankingType === rankingType;
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToCurrentMenuItem();
    });
  },
  methods: {
    /**
     * 現在のメニューアイテムを中央に表示
     * @returns {void}
     */
    scrollToCurrentMenuItem() {
      const menu = document.getElementById('financial-item-ranking-menu');
      const currentItem = menu.querySelector('[data-selected="true"]');
      if (currentItem) {
        const currentItemOffset = currentItem.offsetLeft + currentItem.offsetWidth / 2;
        const centerOffset = currentItemOffset - menu.offsetWidth / 2;
        menu.scrollTo({
          left: centerOffset,
          behavior: 'auto',
        });
      }
    },
  },
};
</script>
