/*
株式ランキングに関する処理をまとめたMixin
*/
const RANKING_NAME = {
  rise: '株価値上がり',
  fall: '株価値下がり',
  volume: '出来高',
  vroc: '出来高変化率',
  per: 'PER(調整後)',
  pbr: 'PBR',
  buy_picks_rise: '買い予想数上昇',
  sell_picks_rise: '売り予想数上昇',
  buy_picks_total: '買い予想総数',
  sell_picks_total: '売り予想総数',
  dividend_yield: '配当利回り',
  consecutive_increased_dividend: '連続増配',
  payout_ratio: '配当性向',
  increased_dividend_rate_1year: '増配率(1年)',
  increased_dividend_rate_3years: '増配率(3年)',
  increased_dividend_rate_5years: '増配率(5年)',
  market_capitalization: '時価総額',
  sales: '売上高',
  operating_income: '営業利益',
  ordinary_income: '経常利益',
  net_income: '当期利益',
  eps: '調整１株当り利益',
  bps: '１株当り純資産',
  total_assets: '総資産',
  net_assets: '純資産',
  capital: '資本金',
  capital_ratio: '自己資本比率',
  roa: 'ROA',
  roe: 'ROE',
  listed_on: '上場年月日',
};

const RANKING_CATEGORY = [
  {
    name: '株価',
    rankingTypes: ['rise', 'fall', 'volume', 'vroc', 'per', 'pbr'],
  },
  {
    name: '売買予想',
    rankingTypes: ['buy_picks_rise', 'sell_picks_rise', 'buy_picks_total', 'sell_picks_total'],
  },
  {
    name: '配当',
    rankingTypes: [
      'dividend_yield',
      'consecutive_increased_dividend',
      'payout_ratio',
      'increased_dividend_rate_1year',
      'increased_dividend_rate_3years',
      'increased_dividend_rate_5years',
    ],
  },
  {
    name: '企業',
    rankingTypes: [
      'market_capitalization',
      'sales',
      'operating_income',
      'ordinary_income',
      'net_income',
      'eps',
      'bps',
      'total_assets',
      'net_assets',
      'capital',
      'capital_ratio',
      'roa',
      'roe',
      'listed_on',
    ],
  },
];

const FinancialItemRankingMixin = {
  methods: {
    /**
     * 指定されたランキングタイプの名前を取得します。
     * @param {string} rankingType - ランキングタイプ
     * @returns {string} - ランキングの名前
     */
    financialItemRankingName(rankingType) {
      return RANKING_NAME[rankingType];
    },

    /**
     * 指定されたランキングタイプが属するカテゴリを取得します。
     * @param {string} rankingType - ランキングタイプ
     * @returns {string} - ランキングカテゴリ
     */
    financialItemRankingCategoryName(rankingType) {
      for (const categoryObj of RANKING_CATEGORY) {
        if (categoryObj.rankingTypes.includes(rankingType)) {
          return categoryObj.name;
        }
      }
    },

    /**
     * 指定されたカテゴリに属するランキングタイプを取得します。
     * @param {string} categoryName - ランキングカテゴリの名前
     * @returns {Array} - ランキングタイプの配列
     */
    financialItemRankingTypesByCategory(categoryName) {
      const categoryObj = RANKING_CATEGORY.find((category) => category.name === categoryName);
      return categoryObj ? categoryObj.rankingTypes : [];
    },

    /**
     * ランキングカテゴリの一覧を取得します。
     * @returns {Array} - ランキングカテゴリの配列
     */
    financialItemRankingCategoryList() {
      return RANKING_CATEGORY.map((category) => category.name);
    },
  },
};

export default FinancialItemRankingMixin;
